import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import "./etapes.css";
import {
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import SocieteModal from "./SocieteModal";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import { getAll } from "../../../Services/SocieteService";
import { getConsultantsAndManagers } from "../../../Services/UserService";
import moment from "moment";
import { Box } from "@material-ui/core";
import Input from "react-phone-number-input";
import "react-phone-number-input/style.css";
import useDebounce from "../../../../utils/useDebounce";
import { v4 } from "uuid";
import { sources } from "../../../../utils/SourceUtil";
import { villes } from "../../../../utils/VilleUtil";
import { contrats } from "../../../../utils/ContratUtil";

export const Etape1 = ({
  linkedin,
  titre,
  nom,
  prenom,
  telephone,
  email,
  employeur,
  fonction,
  datedebut,
  seniorite,
  manager,
  consultant,
  sachezque,
  setLinkedin,
  setTitre,
  setPrenom,
  setNom,
  setTelephone,
  setEmail,
  setEmployeur,
  setFonction,
  setDateDebut,
  setSeniorite,
  setManager,
  setConsultant,
  setSachezQue,
  errors,
  setErrors,
  snackbarShowMessage,
  source, setSource,
    localisation, setLocalisation,
    status, setStatus

}) => {
  const [open, setOpen] = useState(false);
  const [loadUsers, setLoadUsers] = useState(true);
  const [managers, setManagers] = useState([]);
  const [sachezQueCounter, setSachezQueCounter] = useState(
    300 - sachezque?.length
  );
  const [firstChar, setFirstChar] = useState("");
  const debouncedSearch = useDebounce(firstChar, 1000);
  const [societeData, setSocieteData] = useState({
    data: [],
    isLoading: false,
  });

  const handleTimeChange = (value) => {
    const formatedYear = moment(value).format("YYYY");
    setDateDebut(value);
    let currentDate = new Date().getFullYear();
    if (formatedYear !== "Invalid date") {
      setSeniorite(currentDate - formatedYear);
    }
  };

  const setSocieteDataValue = (data = [], isLoading = true) => {
    setSocieteData({
      data,
      isLoading,
    });
  };

  React.useEffect(() => {
    setSocieteDataValue();
    const searchParam =
      debouncedSearch !== "" ? `?firstChar=${debouncedSearch}` : "";
    getAll(searchParam)
      .then(({ data }) => {
        setSocieteDataValue(data, false);
      })
      .catch((error) => {
        snackbarShowMessage(
          "Erreur de chargement du des sociétés.",
          "error",
          4000
        );
        setSocieteDataValue();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  React.useEffect(() => {
    let active = true;
    window.scrollTo({ top: 0, behavior: "smooth" });

    (async () => {
      setLoadUsers(true);
      try {
        const managerData = await getConsultantsAndManagers();
        if (!active) {
          return;
        }
        setManagers(managerData.data.managers);
        setLoadUsers(false);
      } catch (error) {
        if (!active) {
          return;
        }
        setOpen(true);
        snackbarShowMessage(
          "Erreur de chargement des consultants et managers.",
          "error",
          4000
        );
        setLoadUsers(false);
      }
    })();

    return () => {
      active = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSociete = (event) => {
    setFirstChar(event.target.value);
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} xl={6}>
        <Card className="cards" style={{ height: "100%" }}>
          <label className="input-label" htmlFor="idCandidat">
            TITRE <span className="required-input">*</span>
          </label>
          <FormControl variant="outlined" fullWidth={true} margin="normal">
            <InputLabel
              {...(errors.titre && {
                error: true,
              })}
              shrink
            >
              {errors.titre}
            </InputLabel>
            <Select
              value={titre?.trim()}
              name="titre"
              onChange={(e) => setTitre(e.target.value)}
              {...(errors.titre && {
                error: true,
              })}
              input={
                <OutlinedInput
                  name="titre"
                  {...(errors.titre && {
                    labelWidth: 230,
                  })}
                  notched
                ></OutlinedInput>
              }
            >
              <MenuItem value={"Mr"}>Mr</MenuItem>
              <MenuItem value={"Mme"}>Mme</MenuItem>
            </Select>
          </FormControl>
          <label className="input-label" htmlFor="prenom">
            PRÉNOM<span className="required-input">*</span>
          </label>
          <TextField
            onChange={(e) =>
              setPrenom(e.target.value.replace(/^\w/, (c) => c.toUpperCase()))
            }
            name="prenom"
            value={prenom}
            placeholder="Prenom du candidat."
            margin="normal"
            variant="outlined"
            autoComplete="off"
            fullWidth={true}
            {...(errors.prenom && {
              error: true,
              label: errors.prenom,
            })}
          />
          <label className="input-label">
            NOM<span className="required-input">*</span>
          </label>
          <TextField
            name="nom"
            value={nom}
            onChange={(e) => setNom(e.target.value.toUpperCase())}
            margin="normal"
            variant="outlined"
            autoComplete="off"
            fullWidth={true}
            placeholder="Nom du candidat."
            {...(errors.nom && {
              error: true,
              label: errors.nom,
            })}
          />
          <label className="input-label">
            TÉLÉPHONE<span className="required-input"></span>
          </label>
          <Input
            defaultCountry="FR"
            onChange={setTelephone}
            name="telephone"
            value={telephone}
            margin="normal"
            variant="outlined"
            autoComplete="off"
            {...(errors.telephone && {
              error: true,
              label: errors.telephone,
            })}
          />
          <label className="input-label">
            EMAIL<span className="required-input">*</span>
          </label>
          <TextField
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            variant="outlined"
            autoComplete="off"
            fullWidth={true}
            placeholder="Email du candidat."
            {...(errors.email && {
              error: true,
              label: errors.email,
            })}
          />
          <label className="input-label">
            LINKEDIN<span className="required-input">*</span>
          </label>
          <span class="specification-label">(Sales Navigator interdit)</span>
          <TextField
            name="linkedin"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
            margin="normal"
            variant="outlined"
            autoComplete="off"
            fullWidth={true}
            placeholder="https://linkedin.com/in/profile."
            {...(errors.linkedin && {
              error: true,
              label: errors.linkedin,
            })}
          />
          <label className="input-label" htmlFor="source">
            SOURCE<span className="required-input">*</span>
          </label>
          <Box>
            <Select
              value={source}
              name="source"
              fullWidth
              onChange={(e) => setSource(e.target.value)}
              {...(errors.source && {
                error: true,
              })}
              input={
                <OutlinedInput
                  name="source"
                  {...(errors.source && {
                    error: true,
                  })}
                  notched
                ></OutlinedInput>
              }
            >
              {
                sources.map((source) => (
                  <MenuItem value={source.id} key={v4()}>
                    <span style={{ textTransform: "capitalize" }}>
                      {source.name}
                    </span>
                  </MenuItem>
                ))
              }
            </Select>
            <FormHelperText ><Box sx={{ color: "red" }}>{errors.source}</Box></FormHelperText>
          </Box>
          <label className="input-label" htmlFor="status">
            STATUT
          </label>
          <Box>
            <Select
                value={status}
                name="status"
                fullWidth
                onChange={(e) => setStatus(e.target.value)}
                {...(errors.status && {
                  error: true,
                })}
                input={
                  <OutlinedInput
                      name="status"
                      {...(errors.status && {
                        error: true,
                      })}
                      notched
                  ></OutlinedInput>
                }
            >
              {
                contrats.map((contrat) => (
                    <MenuItem value={contrat.id} key={v4()}>
                    <span style={{textTransform: "capitalize"}}>
                      {contrat.name}
                    </span>
                    </MenuItem>
                ))
              }
            </Select>
            <FormHelperText><Box sx={{color: "red"}}>{errors.status}</Box></FormHelperText>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6} xl={6}>
        <Card className="cards">
          <label className="input-label" htmlFor="idCandidat">
            SOCIÉTÉ<span className="required-input"></span>
          </label>
          <SocieteModal
              errors={errors}
              setErrors={setErrors}
              snackbarShowMessage={snackbarShowMessage}
          />
          <Autocomplete
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              getOptionSelected={(option, value) => option.uuid === value.uuid}
              getOptionLabel={(option) => option.nomSociete}
              onChange={(event, values) => {
                setEmployeur(values);
              }}
              options={societeData.data}
              loading={societeData.isLoading}
              loadingText={"Chargement en cours..."}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant="outlined"
                      fullWidth={true}
                      margin="normal"
                      onChange={handleChangeSociete}
                      placeholder={employeur ? employeur.nomSociete : ""}
                      {...(errors.employeur && {
                        error: true,
                        label: errors.employeur,
                      })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                      }}
                  />
              )}
          />
          <label className="input-label">
            FONCTION <span className="required-input">*</span>
          </label>
          <TextField
              name="fonction"
              value={fonction}
              onChange={(e) => setFonction(e.target.value)}
              margin="normal"
              variant="outlined"
              autoComplete="off"
              fullWidth={true}
              placeholder="Fonction du candidat."
              {...(errors.fonction && {
                error: true,
                label: errors.fonction,
              })}
          />
          <label className="input-label" htmlFor="idCandidat">
            DATE DÉBUT EXPÉRIENCE<span className="required-input">*</span>
          </label>
          <KeyboardDatePicker
              views={["month", "year"]}
              invalidDateMessage=""
              autoOk={true}
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              margin="normal"
              value={datedebut}
              onChange={(value) => handleTimeChange(value)}
              disableFuture
              fullWidth
          />
          <FormHelperText><Box sx={{color: "red"}}>{errors.datedebut}</Box></FormHelperText>
          <br/>
          <label className="input-label" htmlFor="idCandidat">
            SENIORITÉ<span className="required-input"></span>
          </label>
          <TextField
              value={seniorite}
              fullWidth={true}
              variant="outlined"
              placeholder="Nombre d'année d'expérience."
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              {...(errors.seniorite && {
                error: true,
                label: errors.seniorite,
              })}
          />
          <label className="input-label" htmlFor="source">
            LOCALISATION
          </label>
          <Box>
            <Select
                value={localisation}
                name="localisation"
                fullWidth
                onChange={(e) => setLocalisation(e.target.value)}
                {...(errors.localisation && {
                  error: true,
                })}
                input={
                  <OutlinedInput
                      name="source"
                      {...(errors.localisation && {
                        error: true,
                      })}
                      notched
                  ></OutlinedInput>
                }
            >
              {
                villes.map((ville) => (
                    <MenuItem value={ville.id} key={v4()}>
                    <span style={{textTransform: "capitalize"}}>
                      {ville.name}
                    </span>
                    </MenuItem>
                ))
              }
            </Select>
            <FormHelperText><Box sx={{color: "red"}}>{errors.localisation}</Box></FormHelperText>
          </Box>
          <label className="input-label" htmlFor="idCandidat">
            CONSULTANT
          </label>
          <FormControl variant="outlined" fullWidth={true} margin="normal">
            <InputLabel
                {...(errors.consultant && {
                  error: true,
                })}
                shrink
            >
              {errors.consultant}
            </InputLabel>
            {loadUsers ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress/>
                </Box>
            ) : (
                <Select
                    value={consultant}
                    name="consultant"
                    onChange={(e) => setConsultant(e.target.value)}
                    {...(errors.consultant && {
                      error: true,
                    })}
                    input={
                      <OutlinedInput
                          name="consultant"
                          {...(errors.consultant && {
                            labelWidth: 290,
                          })}
                          notched
                      ></OutlinedInput>
                    }
                >
                  {loadUsers ? (
                      <CircularProgress/>
                  ) : (
                      managers.map((consultant) => (
                          <MenuItem value={consultant.uuid} key={consultant.uuid}>
                      <span style={{textTransform: "capitalize"}}>
                        {consultant.user}
                      </span>
                          </MenuItem>
                      ))
                  )}
                </Select>
            )}
          </FormControl>
          <label className="input-label" htmlFor="idCandidat">
            SACHEZ QUE<span className="required-input"></span>
          </label>
          <TextField
              multiLine
              minRows={4}
              value={sachezque}
              name="sachezque"
              inputProps={{maxLength: 300}}
              onChange={(e) => {
                setSachezQue(e.target.value);
                setSachezQueCounter(300 - e.target.value.length);
              }}
              variant="outlined"
              placeholder="Plus d'informations sur le candidat."
              fullWidth={true}
              margin="normal"
          />
          {sachezQueCounter || 300}
        </Card>
      </Grid>
    </>
  );
};
