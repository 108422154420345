import {
  CircularProgress,
  FormControlLabel,
  Switch,
  Box,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import { TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import React, { useState, useRef } from "react";
import MissionModal from "../Controls/MissionModal";
import MUIDataTable from "mui-datatables";
import { useParams } from "react-router-dom";
import {
  getAllProcesses,
  deleteProcess,
  agreementProcess,
  updateProcessDate
} from "../../../Services/ProcessService";
import { terminerMission } from "../../../Services/MissionService";
import moment from "moment";
import AppointmentModal from "./Modals/CandidatProcess/AppointmentModal";
import SendCandidatToClientModal from "./Modals/CandidatProcess/SendCandidatToClientModal";
import AppointmentsListModal from "./Modals/CandidatProcess/AppointmentsListModal";
import "../visuialisation.css";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import DialogDeleteCustom from "../../../../components/DialogDeleteCustom/DialogDeleteCustom";
import EventIcon from '@material-ui/icons/Event';
import DeleteIcon from '@mui/icons-material/Delete';


const Process = ({
  setAutresPieces,
  candidatName,
  candidatEmail,
  candidatTel,
  candidatAccountManager,
  candidatConsultant,
  candidatSeniorite,
  candidatCommentaire,
  candidatPointFort,
  candidatSoftskills,
  candidatLangue2,
  candidatAnglais,
  candidatActuPackage,
  candidatActuFixe,
  candidatActuVariable,
  candidatActuInteress,
  candidatSouhaitePackage,
  candidatsouhaiteFixe,
  candidatsouhaiteVariable,
  candidatsouhaiteInteress,
  candidatpreAvis,
  candidatdispoLe,
  numCandidat,
  snackbarShowMessage, processId,
  ...rest
}) => {
  const useStyles = makeStyles(() => ({
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }))
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const parametters = useParams();
  const idCandidat = rest.idCandidat ?? parametters.idCandidat;
  const [missionPassive, setMissionPassive] = useState(false);
  const [processes, setProcesses] = useState([]);
  const [loadingtoFinish, setLoadingToFinish] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [missionInfos, setMissionInfos] = useState({ id: "", nom: "" });
  const [openFinishDialog, setOpenFinishDialog] = useState(false);
  const missionModalProps = {
    processes,
    setReloadData,
    reloadData,
    snackbarShowMessage,
  };
  const apptModalProps = { reloadData, setReloadData };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isActive = useRef();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);


  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  const handleCloseFinishDialog = () => {
    if (!loadingtoFinish) {
      setOpenFinishDialog(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    if (!loadingToDelete) {
      setOpenDeleteDialog(false);
    }
  };

  const handleDeleteProcess = (e, params) => {
    e.preventDefault();
    setMissionInfos({ id: params.rowData[11], nom: params.rowData[3] });
    setOpenDeleteDialog(true);
  };

  const handleFinishMission = () => {
    if (!loadingtoFinish) {
      setLoadingToFinish(true);
      (async () => {
        try {
          const { data } = await terminerMission(missionInfos.id);
          if (!isActive.current) {
            return;
          }
          snackbarShowMessage(data);
          setLoadingToFinish(false);
          handleCloseFinishDialog();
          setReloadData(!reloadData);
        } catch (error) {
          if (!isActive.current) {
            return;
          }
          snackbarShowMessage(error.response.data.error.detail, "error", 4000);
          setLoadingToFinish(false);
        }
      })();
    }
  };
  const handleRvTechDateSelection = (date, tableMeta) => {
    setSelectedDate(date);
    handleRvTechDateChange(date, tableMeta.rowData[11]);
    setShowCalendar(false);
  };
  const handleRvClientDateSelection = (date, tableMeta) => {
    setSelectedDate(date);
    handleRvClientDateChange(date, tableMeta.rowData[11]);
    setShowCalendar(false);
  };
  const handleRvClosingDateSelection = (date, tableMeta) => {
    setSelectedDate(date);
    handleRvClosingDateChange(date, tableMeta.rowData[11]);
    setShowCalendar(false);
  };


  const deleteProcessFromCandidat = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        const { data } = await deleteProcess(missionInfos.id);
        if (!isActive.current) {
          return;
        }
        snackbarShowMessage(data);
        setLoadingToDelete(false);
        handleCloseDeleteDialog();
        setReloadData(!reloadData);
      } catch (error) {
        snackbarShowMessage(error.response.data.error.detail, "error", 4000);
        setLoadingToDelete(false);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setProcesses([]);
        const status = missionPassive
            ? "bad58bee-1b80-448c-9010-03cad8526233"
            : "910f357b-0b13-4f66-89a8-5c06964c1dcf";


        const newProccessesActive = await getAllProcesses(
            idCandidat,
            page,
            50,
            status
          );

        setProcesses(newProccessesActive.data.processes);
        setCount(newProccessesActive.data.count);
      } catch (error) {
        snackbarShowMessage(error.response.data.error.detail, "error", 4000);
      } finally {
        setLoading(false);
      }
    })();
  }, [page, missionPassive, idCandidat, reloadData]);

  React.useEffect(() => {
    isActive.current = true;
    return () => {
      isActive.current = false;
    };
  });

  const handleAgreementClient = (processId) => {
    snackbarShowMessage("Patientez svp!", "warning", null);
    agreementProcess(processId).then(response => {
      snackbarShowMessage("Process accordé.", "success", 4000);
      setReloadData(!reloadData);
    }).catch(error => {
      snackbarShowMessage(error.response.data.error.detail, "error", 4000);
    })
  };
  const handleRvTechDateChange = async (date,prId) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    await handleDateChange(prId, 'rvTech', formattedDate);
    setReloadData(!reloadData);
  };
  const handleRvTechDateDeletion = async (prId) => {
    await handleDateChange(prId, 'rvTech', null);
    setReloadData(!reloadData);
  };
  const handleRvClientDateDeletion = async (prId) => {
    await handleDateChange(prId, 'rvClient', null);
    setReloadData(!reloadData);
  };
  const handleRvClosingDateDeletion = async (prId) => {
    await handleDateChange(prId, 'rvClosing', null);
    setReloadData(!reloadData);
  };

  const handleRvClientDateChange = async (date,prId) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    await handleDateChange(prId, 'rvClient', formattedDate);
    setReloadData(!reloadData);
  };

  const handleRvClosingDateChange = async (date,prId) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    await handleDateChange(prId, 'rvClosing', formattedDate);
    setReloadData(!reloadData);
  };

  const handleDateChange = async (processId, dateType, newDate) => {

    try {
      const response = await updateProcessDate(processId, dateType, newDate);
      if (response.ok) {
        console.log("Date updated successfully");

      } else {
        console.error("Failed to update date");

      }
    } catch (error) {
      console.error("Error updating date:", error);

    }
  };



  const columns = [
    {
      name: "missionId",
      label: "MISSION ID",
      options: {
        display: false,
      },
    },
    {
      name: "nom_societe",
      label: "SOCIETE",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "200px",
            padding: "0px 12px",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            maxWidth: "200px",
            padding: "2px 12px",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
              <Box data-toggle="tooltip" title={value}>
                <Link
                    to={{ pathname: `/societe/${tableMeta.rowData[14]}/view` }}
                    className="d-print-none font-sm"
                    style={{ textDecoration: "none" }}
                >
                  {value}
                </Link>
              </Box>
          );
        },
      },
    },
    {
      name: "statut",
      label: "STATUT",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100px",
            padding: "0px 12px",
          },
        }),
        setCellHeaderProps: () => ({
          style: { justifyContent: "space-around", padding: "0px 12px" },
        }),
      },
    },

    {
      name: "nom_mission",
      label: "MISSION",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100px",
            padding: "0px 12px",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
            maxWidth: "100px",
            padding: "2px 12px",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
              <Box data-toggle="tooltip" title={value}>
                <Link
                    to={{
                      pathname: `/societe/${tableMeta.rowData[11]}/missions/${tableMeta.rowData[0]}/view`,
                    }}
                    className="d-print-none font-sm"
                    style={{ textDecoration: "none" }}
                >
                  {value}
                </Link>
              </Box>
          );
        },
      },
    },/*
    {
      name: "numMission",
      label: "N°",
      options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "200px",
            padding: "0px 12px",
          }
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
              <Box
                  data-toggle="tooltip"
                  title={value}
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
              >
                <Link
                    to={{
                      pathname: `/societe/${tableMeta.rowData[11]}/missions/${tableMeta.rowData[0]}/view`,
                    }}
                    className="d-print-none font-sm"
                    style={{ textDecoration: "none" }}
                >
                  {value}
                </Link>
              </Box>
          );
        },
      },
    },*/
    {
      name: "step",
      label: "STEP",
      options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "200px",
            padding: "0px 12px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "200px",
            padding: "0px 12px",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const testInterne = tableMeta.rowData[6];
          const envoiClient = tableMeta.rowData[7];
          const rvClient = tableMeta.rowData[8];
          const rvClosing = tableMeta.rowData[9];
          const status = value;
          const expectedStatus = [
            "Propale",
            "Embauche/Integration",
            "REFUSÉ PAR CLIENT",
            "REFUSÉ PAR CANDIDAT",
            "REFUS PROPALE"
          ]
          let stepLabel = "Préquatif RH";

          if (expectedStatus.includes(status)) {
            stepLabel = value;
          } else if (rvClosing && rvClosing.date) {
            stepLabel = "RV Closing";
          } else if (rvClient && rvClient.date) {
            stepLabel = "RV CLIENT";
          } else if (envoiClient && envoiClient.date) {
            stepLabel = "ENVOI CLIENT";
          } else if (testInterne && testInterne.date) {
            stepLabel = "TEST INTERNE";
          }

          return <span>{stepLabel}</span>;
        },
      },
    },
    {
      name: "dateAccrochage",
      label: "Préquatif RH",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
              <Box
                  data-toggle="tooltip"
                  title={value != null ? moment.utc(value.date).format("DD-MM-YYYY") : "-"}
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "left",
                    width: "90px",
                    color: "#007bff",
                  }}
              >
                {value != null ? moment.utc(value.date).format("DD-MM-YYYY") : "-"}

              </Box>
          );
        },
      },
    },

    {
      name: "rvTech",
      label: "TEST INTERNE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let isDisabled = tableMeta.rowData[5] == null;
          if (!isDisabled) {
            if (value && value.date) {

              return (
                  <div style={{display: 'flex', alignItems: 'center', width: '130px'}}>
                    <DatePicker
                        value={value.date ? moment(value.date, "YYYY-MM-DD").toDate() : null}
                        onChange={date => handleRvTechDateChange(date, tableMeta.rowData[11])}
                        format="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                          style: {color: "#007bff", width: '100%'}
                        }}
                    />
                    <DeleteIcon
                        onClick={
                      () => handleRvTechDateDeletion(tableMeta.rowData[11])}
                        style={{cursor: 'pointer', marginLeft: 20, color: 'red', fontSize: '15px'}}
                    />
                  </div>
              );
            } else {
              return (
                  <div>
                  <div
                        onClick={() => setShowCalendar(true)}
                        style={{cursor: "pointer", color: "#007bff"}}
                    >
                      <EventIcon/>
                    </div>
                    {showCalendar && (
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => handleRvTechDateSelection(date, tableMeta)}
                            dateFormat="dd/MM/yyyy"
                            renderCustomHeader={({date, decreaseMonth, increaseMonth}) => (
                                <div>
                                  <button onClick={decreaseMonth}>{"<"}</button>
                                  <span>{moment(date).format("MMMM YYYY")}</span>
                                  <button onClick={increaseMonth}>{">"}</button>
                                </div>
                            )}
                            renderInput={(params) => <TextField {...params} />}
                            inline
                        />
                    )}
                  </div>
              );
            }
          }
        }
      }
    },
    {
      name: "sent",
      label: "ENVOI CLIENT",
      options: {
        setCellHeaderProps: () => ({style: {padding: "2px 12px"}}),
        setCellProps: () => ({style: {padding: "0px 12px"}}),
        customBodyRender: (value, tableMeta, updateValue) => {
          let isDisabled = tableMeta.rowData[6] == null;
          if (!isDisabled) {
            return (
              <>
                <SendCandidatToClientModal
                    {...{
                      reloadData,
                      setReloadData,
                      candidatName,
                      setAutresPieces,
                      missions: processes,
                      selectedMission: tableMeta.rowData[0],
                      sentDate:
                          value && value.date
                              ? moment(value.date).format("DD/MM/yyyy")
                              : "Envoyer",
                      candidatAccountManager,
                      candidatConsultant,
                      candidatSeniorite,
                      candidatCommentaire,
                      candidatPointFort,
                      candidatSoftskills,
                      candidatLangue2,
                      candidatAnglais,
                      candidatActuPackage,
                      candidatActuFixe,
                      candidatActuVariable,
                      candidatActuInteress,
                      candidatSouhaitePackage,
                      candidatsouhaiteFixe,
                      candidatsouhaiteVariable,
                      candidatsouhaiteInteress,
                      candidatpreAvis,
                      candidatdispoLe,
                      numCandidat,
                    }}
                    processId={
                      processes.find(
                          (proces) => proces.missionId === tableMeta.rowData[0]
                      ).processId
                    }
                    key={tableMeta.rowData[0]}
                    isMissionActive={tableMeta.rowData[2] === "ACTIVE"}
                    disabled={true}
                />
                {value ? (
                    ""
                ) : (
                    <>
                      {" | "}
                      <a
                          href="#/"
                          onClick={(e) => handleDeleteProcess(e, tableMeta)}
                          style={{ textDecoration: "none", color: "red" }}
                      >
                        X
                      </a>
                    </>
                )}
              </>
          );
          }
        },
      },
    },
    {
      name: "rvClient",
      label: "RV CLIENT",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let isDisabled = tableMeta.rowData[7] == null;
          if (!isDisabled) {
            if (value && value.date) {
              return (
                  <div style={{display: 'flex', alignItems: 'center', width: '130px'}}>
                    <DatePicker
                        value={value.date ? moment(value.date, "YYYY-MM-DD").toDate() : null}
                        onChange={date => handleRvClientDateChange(date, tableMeta.rowData[11])}
                        format="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                          style: {color: "#007bff"},
                        }}
                    />
                    <DeleteIcon
                        onClick={() => handleRvClientDateDeletion(tableMeta.rowData[11])}
                        style={{cursor: 'pointer', marginLeft: 20, color: 'red', fontSize: '15px'}}
                    />
                  </div>
              );
            } else {
              return (
                  <div>
                  <div
                        onClick={() => setShowCalendar(true)}
                        style={{cursor: "pointer", color: "#007bff"}}
                    >
                      <EventIcon/>
                    </div>
                    {showCalendar && (
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => handleRvClientDateSelection(date, tableMeta)}
                            dateFormat="dd/MM/yyyy"
                            renderCustomHeader={({date, decreaseMonth, increaseMonth}) => (
                                <div>
                                  <button onClick={decreaseMonth}>{"<"}</button>
                                  <span>{moment(date).format("MMMM YYYY")}</span>
                                  <button onClick={increaseMonth}>{">"}</button>
                                </div>
                            )}
                            renderInput={(params) => <TextField {...params} />}
                            inline
                        />
                    )}
                  </div>
              );
            }
          }
        }
      }
    },

    {
      name: "rvClosing",
      label: "RV Closing",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let isDisabled = tableMeta.rowData[8] == null;
          if (!isDisabled) {
            if (value && value.date) {

              return (
                  <div style={{display: 'flex', alignItems: 'center', width: '130px'}}>
                    <DatePicker
                        value={value.date ? moment(value.date, "YYYY-MM-DD").toDate() : null}
                        onChange={date => handleRvClosingDateChange(date, tableMeta.rowData[11])}
                        format="dd/MM/yyyy"
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                          style: {color: "#007bff"},
                        }}
                    />
                    <DeleteIcon
                        onClick={() => handleRvClosingDateDeletion(tableMeta.rowData[11])}
                        style={{cursor: 'pointer', marginLeft: 20, color: 'red', fontSize: '15px'}}
                    />
                  </div>
              );
            } else {
              return (
                  <div>
                  <div
                        onClick={() => setShowCalendar(true)}
                        style={{cursor: "pointer", color: "#007bff"}}
                    >
                      <EventIcon/>
                    </div>
                    {showCalendar && (
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => handleRvClosingDateSelection(date, tableMeta)}
                            dateFormat="dd/MM/yyyy"
                            renderCustomHeader={({date, decreaseMonth, increaseMonth}) => (
                                <div>
                                  <button onClick={decreaseMonth}>{"<"}</button>
                                  <span>{moment(date).format("MMMM YYYY")}</span>
                                  <button onClick={increaseMonth}>{">"}</button>
                                </div>
                            )}
                            renderInput={(params) => <TextField {...params} />}
                            inline
                        />
                    )}
                  </div>
              );
            }
          }
        }
      }
    },
    {
      name: "countReveal",
      label: "RELANCES",
      options: {
        display: false,
      },
    },
    {
      name: "processId",
      label: "N° PROCESS",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "societeId",
      label: "N° SOCIETE",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      },
    },
    {
      name: "datePropale",
      label: "PROPALE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let formattedDate = value && moment(new Date(value)).isValid()
              ? moment(new Date(value)).format("DD/MM/YYYY")
              : "-";

          return (
              <Box
                  data-toggle="tooltip"
                  title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "left",
                    width: "90px",
                    color: "#007bff",
                  }}
              >
                {formattedDate}
              </Box>
          );
        },
      },
    },
    {
      name: "dateEmbauche",
      label: "EMB/INTÉGRATION",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let formattedDate;
          formattedDate = value && moment(new Date(value)).isValid()
              ? moment(new Date(value)).format("DD/MM/YYYY")
              : "-";
          return (
              <Box
                  data-toggle="tooltip"
                  title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "left",
                    width: "90px",
                    color: "#007bff",
                  }}
              >
                {formattedDate}
              </Box>
          );
        },
      },
    },
    {
        name: "actions",
        label: "ISSUE",
        options: {
          setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
          setCellProps: () => ({ style: { padding: "0px 12px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                {tableMeta.rowData[2] !== "PASSIVE" ? (
                  <div className={ classes.actionContainer }>
                    {tableMeta.rowData[4] ? (
                      <AppointmentModal
                        {...apptModalProps}
                        processId={
                          processes.find(
                            (proces) => proces.missionId === tableMeta.rowData[0]
                          ).processId
                        }
                        lastAppointment={tableMeta.rowData[8]}
                      />
                    ) : (
                      ""
                    )}
                  <IconButton 
                    data-toggle="tooltip" 
                    title="Supprimer" 
                    aria-label="Supprimer" 
                    onClick={(e) => handleDeleteProcess(e, tableMeta)} 
                    style={{ color: "#EF6262" }}
                  >
                    <Delete />
                  </IconButton>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          },
        },
    },
    {
      name: "dateIntegration",
      label: "dateIntegration",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "0px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value).format("DD/MM/yyyy")}</>;
        },
      },
    },
  ];

  const options = {
    serverSide: true,
    count: count,
    rowsPerPage: 50,
    tableBodyMaxHeight: "44vh",
    textLabels: {
      body: {
        noMatch: loading ? (
            <CircularProgress />
        ) : (
            "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    columns: false,
    search: false,
    download: false,
    viewColumns: false,
  };

  const dialogAcheverProps = {
    handleClose: handleCloseFinishDialog,
    handleOpen: openFinishDialog,
    handleDelete: handleFinishMission,
    loading: loadingtoFinish,
    label: ` Voulez vous vraiment achever la mission: ${missionInfos.nom}?`,
    title: "Achever la mission",
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDeleteDialog,
    handleOpen: openDeleteDialog,
    handleDelete: deleteProcessFromCandidat,
    loading: loadingToDelete,
    label: `Voulez vous supprimer le process: ${missionInfos.nom}?`,
    title: "Supprimer le process?",
  };

  return (
      <>
        <DialogDeleteCustom {...dialogAcheverProps} />
        <DialogDeleteCustom {...dialogDeleteProps} />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MissionModal {...missionModalProps} />
          <Box>
            <label className="input-label" htmlFor="tachesTermines">
              MISSIONS PASSIVES
            </label>
            <FormControlLabel
                control={
                  <Switch
                      checked={missionPassive}
                      onChange={(e) => setMissionPassive(e.target.checked)}
                      name="missionPassives"
                      color="primary"
                  />
                }
                style={{ marginLeft: "0.2em", marginRight: 0 }}
            />
          </Box>
        </div>
        <MUIDataTable data={processes} columns={columns} options={options} />
      </>
  );
};

export default withSnackbar(Process);
