import React, {useState, useEffect} from "react";
import { Delete, LinkedIn, RemoveRedEye, Search } from "@material-ui/icons";
import {
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import "./candidattable.css";
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { Box } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { getUserData } from "../../../../utils/FunctionHelpers";
import ShowCvCandidat from "../../VisualiserCandidat/ShowCVCandidat";
import { FiArchive } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { SetSelectedCandidatBeAddToSelection } from "../../../../redux/candidats/actions/candidatsActions";
import { v4 } from "uuid";
import { displaySwipeableDrawerSummaryCandidat } from "../../../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { deleteCandidat } from "../../../Services/CandidatService";
import DialogDeleteCustom from "../../../../components/DialogDeleteCustom/DialogDeleteCustom";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { handleOnViewColumnsChange, displayColumn } from "../../../../utils/viewColumns";
import moment from "moment";
import ShowAddCvCandidat from "../../VisualiserCandidat/ShowAddCvCandidat";
import {debounce} from "lodash";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const CandidatTable = ({
  candidatsData,
  handleSearchCandidats,
  parameters,
  snackbarShowMessage,
}) => {
  const classes = useStyles();
  const [candidats, setCandidats] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) =>
    theme.breakpoints.between(767, 1023)
  );
  const isLargeMode = useMediaQuery((theme) =>
    theme.breakpoints.between(1023, 2800)
  );
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const tableBodyMaxHeight = `${isSmall
    ? viewportHeight - 292
    : isMediumMode
    ? viewportHeight - 478
    : isLargeMode
    ? viewportHeight - 355
    : "200"
    }px`;
  const [loadToDelete, setLoadToDelete] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [candidatSelectedToDelete, setCandidatSelectedToDelete] =
    useState(null);

  const handleDeleteCandidat = async (uuid) => {
    try {
      setLoadToDelete(true);
      snackbarShowMessage("Patientez svp...", "warning", null);
      const data = await deleteCandidat(uuid);
      handleSearchCandidats(candidatsData.pageCandidat, parameters);
      snackbarShowMessage(data);
    } catch (error) {
      snackbarShowMessage("Erreur à la suppression du candidat", "error", 4000);
    } finally {
      setLoadToDelete(false);
      setOpenDialogDelete(false);
    }
  };

  const handlePopoverOpen = (event, index) => {
    setCandidats((prevCandidats) => {
      const newState = prevCandidats.map((candidat, indexC) => {
        if (indexC === index) {
          return { ...candidat, anchorEl: event.currentTarget };
        }
        return candidat;
      });
      return newState;
    });
  };

  const handlePopoverClose = (index) => {
    setCandidats((prevCandidats) => {
      const newState = prevCandidats.map((candidat, indexC) => {
        if (indexC === index) {
          return { ...candidat, anchorEl: null };
        }
        return candidat;
      });
      return newState;
    });
  };

  const handleOpenDialog = (candidat) => {
    setCandidatSelectedToDelete({
      id: candidat.id,
      fullName:
        candidat.fullName.charAt(0).toUpperCase() + candidat.fullName.slice(1),
    });
    setOpenDialogDelete(true);
  };

  const debouncedSearch = debounce((searchText, tableState, parameters, handleSearchCandidats) => {
    handleSearchCandidats(tableState.page + 1, {
      ...parameters,
      column: tableState.sortOrder.name,
      sort: tableState.sortOrder.direction,
      fonction: searchText
    });
  }, 800);

  const PopoverComponent = ({ selections, candidat }) => {
    return (
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(candidat.anchorEl)}
        anchorEl={candidat.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List>
          {selections?.map((selection) => (
            <ListItem key={v4()} dense={true} disableGutters={true}>
              <ListItemAvatar>
                <Avatar>
                  <FiArchive />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={selection?.nom} />
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  };

  useEffect(() => {
    const newCandidats = candidatsData.candidats.map((candidat) => ({
      ...candidat,
      anchorEl: null,
    }));
    setCandidats(newCandidats);
  }, [candidatsData.candidats]);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const tableName = "candidatColumnVisibility";

  const options = {
    rowsPerPageOptions: [],
    page: candidatsData.pageCandidat - 1,
    jumpToPage: true,
    tableBodyMaxHeight: tableBodyMaxHeight,
    count: candidatsData.candidatsCount,
    rowsPerPage: 50,
    serverSide: true,
    sortOrder: {
      name: candidatsData.sort.column,
      direction: candidatsData.sort.direction,
    },
    textLabels: {
      body: {
        noMatch: candidatsData.isLoading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handleSearchCandidats(tableState.page + 1, {
            ...parameters,
            column: tableState.sortOrder.name,
            sort: tableState.sortOrder.direction,
          });
          break;
        case "sort":
          handleSearchCandidats(tableState.page + 1, {
            ...parameters,
            column: tableState.sortOrder.name,
            sort: tableState.sortOrder.direction,
          });
          break;
        case "search":
          const searchText = tableState.searchText || "";
          debouncedSearch(searchText, tableState, parameters, handleSearchCandidats);
          break;
        default:
      }
    },
    selectableRows: "multiple",
    draggableColumns: {
      enabled: true,
    },
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    customToolbarSelect: (selectedRows) => (
      <CustomToolbarSelect selectedminRows={selectedRows} />
    ),
    onRowSelectionChange: (rowsSelected, allRowsSelected) => {
      const candidatIds = [];
      allRowsSelected.forEach((element) => {
        candidatIds.push(candidatsData.candidats[element.index].id);
      });
      dispatch(SetSelectedCandidatBeAddToSelection(candidatIds));
    },
  };

  const handleCloseDialog = () => {
    if (loadToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    loading: loadToDelete,
    title: "Supprimer le candidat",
  };
  const viewCandidat = (candidatId, tabIndex) => {
    let candidatsIds = [];

    candidats.forEach((candidat) => {
      candidatsIds.push(candidat.id);
    });

    history.push({
      pathname: `/candidats/${candidatId}/view`,
      state: {
        tabIndex: tabIndex,
        candidatsInList: candidatsIds,
        candidatIndex: candidatsIds.indexOf(candidatId),
        numbersTotalOfSelections: candidats.length
      },
    });
  };

  const columns = [
    {
      name: "updatedAt",
      label: "DATE MAJ",
      options: {
        display: displayColumn("updatedAt", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <> {value != null ? moment(value).format("DD/MM/yyyy") : "-"}</>;
        },
      },
    },
    {
      name: "prenom",
      label: "PRENOM",
      options: {
        display: displayColumn("prenom", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textTransform: "capitalize",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "130px",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "130px",
              }}
            >
              {!(isSmall || isMediumMode) && (
                <IconButton
                  onClick={() =>
                    dispatch(
                      displaySwipeableDrawerSummaryCandidat({
                        show: true,
                        id: tableMeta.rowData[4],
                      })
                    )
                  }
                  data-toggle="tooltip"
                  title="Voir resumé"
                >
                  <RemoveRedEye size={"15px"} />
                </IconButton>
              )}
              <Link 
                to={{ pathname: `/candidats/${tableMeta.rowData[4]}/view` }} 
                onClick={(e)=>{
                  e.preventDefault()
                  viewCandidat(tableMeta.rowData[4], 0)
                }}
              >
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    {
      name: "nom",
      label: "NOM",
      options: {
        display: displayColumn("nom", tableName),
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textTransform: "uppercase",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "150px",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "150px",
                }}
              >
                <Link 
                  to={{ pathname: `/candidats/${tableMeta.rowData[4]}/view` }} 
                  onClick={(e)=>{
                    e.preventDefault()
                    viewCandidat(tableMeta.rowData[4], 0)
                  }}
                >
                  {value}
                </Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "numCandidat",
      label: "N°",
      options: {
        display: displayColumn("numCandidat", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100px",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <ShowAddCvCandidat
                  {...{
                    idCandidat: tableMeta.rowData[4],
                    snackbarShowMessage,
                    files: tableMeta.rowData[15],
                  }}
                >
                  {value}
                </ShowAddCvCandidat>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "id",
      label: "N°",
      options: {
        display: false,
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100px",
          },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value}>
                {value.substring(0, 30).replace(/\D/g, "").substring(1, 7)}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "societe",
      label: "SOCIETE",
      options: {
        display: displayColumn("societe", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                <Link
                  to={{ pathname: `/societe/${tableMeta.rowData[6]}/view` }}
                >
                  {value}
                </Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "societeId",
      label: "N° societe",
      options: {
        display: false,
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100px",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                {value}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "telephone",
      label: "MOBILE",
      options: {
        display: displayColumn("telephone", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "150px",
                }}
              >
                {value}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        display: displayColumn("email", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "250px", // Adjusted width
                }}
              >
                {value}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        display: displayColumn("fonction", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
        setCellProps: () => ({ style: { textTransform: "capitalize" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "150px",
                }}
              >
                {value}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "seniorite",
      label: "XP",
      options: {
        display: displayColumn("seniorite", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "80px",
            textAlign: "center",
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "80%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {value}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "linkedin",
      label: "LKD",
      options: {
        display: displayColumn("linkedin", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100px",
          },
        }),
        setCellHeaderProps: () => ({ style: { text: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "flex",
                  width: "55%",
                  justifyContent: "center",
                }}
              >
                <a href={value} target="_blank" rel="noreferrer">
                  <LinkedIn />
                </a>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "consultant",
      label: "CONSULTANT",
      options: {
        display: displayColumn("consultant", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100px",
            textTransform: "capitalize",
          },
        }),
        customBodyRender: (value) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "150px",
                }}
              >
                {value ? value : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "selections",
      label: "VIVIERS",
      options: {
        display: displayColumn("selections", tableName),
        filter: true,
        sort: true,
        sortCompare: (order) => (a, b) => {
          const lengthA = (a.data && a.data.length) || 0;
          const lengthB = (b.data && b.data.length) || 0;
          return (lengthA - lengthB) * (order === 'asc' ? 1 : -1);
        },
        customHeadLabelRender: (columnMeta) => {
          return (
            <Chip
              avatar={
                <Search
                  alt={columnMeta.label}
                  src="/static/images/avatar/1.jpg"
                />
              }
              label={columnMeta.label}
              variant="outlined"
            />
          );
        },
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.length <= 0 ? (
            <div
              aria-haspopup="true"
              data-toggle="tooltip"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "80%",
                display: "flex",
                justifyContent: "center",
              }}
              onMouseEnter={(event) =>
                handlePopoverOpen(event, tableMeta.rowIndex)
              }
              onMouseLeave={() => handlePopoverClose(tableMeta.rowIndex)}
            >
              {value.length}
            </div>
          ) : (
            <>
              <Link
                to={{
                  pathname: `/candidats/${tableMeta.rowData[4]}/view`,
                }}
                onClick={(e)=>{
                  e.preventDefault()
                  viewCandidat(tableMeta.rowData[4], 3)
                }}
                aria-haspopup="true"
                data-toggle="tooltip"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "80%",
                  display: "flex",
                  justifyContent: "center",
                }}
                onMouseEnter={(event) =>
                  handlePopoverOpen(event, tableMeta.rowIndex)
                }
                onMouseLeave={() => handlePopoverClose(tableMeta.rowIndex)}
              >
                {value.length}
                <PopoverComponent
                  selections={value}
                  candidat={candidats[tableMeta.rowIndex]}
                />
              </Link>
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "ACTION",
      options: {
        filter: false,
        sort: false,
        display: getUserData()?.role.includes("ROLE_ADMIN"),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverFlow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100px",
            textTransform: "capitalize",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              data-toggle="tooltip"
              title="Supprimer"
              aria-label="Supprimer"
              onClick={() =>
                handleOpenDialog({
                  id: tableMeta.rowData[4],
                  fullName: `${tableMeta.rowData[1]}  ${tableMeta.rowData[2]}`,
                })
              }
              style={{ color: "#EF6262" }}
            >
              <Delete />
            </IconButton>
          );
        },
      },
    },
    {
      name: "uploadedFiles",
      label: "Files",
      options: {
        display: displayColumn("uploadedFiles", tableName),
        filter: true,
        display: false,
        viewColumns: false
      },
    },
  ];

  const data = candidats.map((item) => ({
    ...item,
    updatedAt: item.updatedAt?.date ? moment(item.updatedAt?.date).toDate().getTime() : null,
  }));

  return (
    <>
      <MUIDataTable
        title="Candidats"
        data={data}
        columns={columns}
        options={options}
      />
      <DialogDeleteCustom
        {...dialogDeleteProps}
        handleDelete={() => handleDeleteCandidat(candidatSelectedToDelete?.id)}
        label={`Voulez - vous vraiment supprimer le candidat ${candidatSelectedToDelete?.fullName} ?`}
      />
    </>
  );
};

export default withSnackbar(CandidatTable);
